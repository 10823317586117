import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../images/logo-3.png';
import SDL from '../images/SDL.mp4';
import globe from '../images/globe.png';

import Social2 from '../images/email.svg';
import Social3 from '../images/insta logo.png';
import Social4 from '../images/twitter.png';

const Home = () => {
  return (
    <div class="hero">
      <video autoPlay={true} loop muted playsInline className="back-video">
        <source src={SDL} type="video/mp4" />
      </video>
      <nav>
        <Link to="/">
          <img src={logo} class="logo" alt="logo" />
        </Link>
      </nav>
      <div class="hn">
        <div class="font-face-content">
          <img
            src={globe}
            style={{ width: '250px', height: '250px' }}
            alt="cross"
          />
        </div>
        <ul
          style={{ textTransform: 'uppercase', transform: 'translateY(-50px)' }}
        >
          <li class="grow">
            <Link to="https://untaintedarts.com/?s=SDL" class="font-face-link">
              Shop
            </Link>
          </li>
          <li class="grow">
            <Link to="/collections" class="font-face-link">
              Collections
            </Link>
          </li>
          <li class="grow">
            <Link to="/about" class="font-face-link">
              About
            </Link>
          </li>
        </ul>
      </div>
      <div class="loop">
        <ul>
          <li>
            <a
              style={{ filter: 'invert(1)' }}
              href=" https://twitter.com/mindofSDL"
            >
              <img
                src={Social4}
                class="Twitter"
                style={{ filter: 'brightness(0)' }}
                alt="twitter"
              />{' '}
            </a>
          </li>
          <li>
            <a href=" https://www.instagram.com/mindofsdl/">
              <img src={Social3} class="Instagram" alt="insta" />{' '}
            </a>
          </li>
          <li>
            <a href="mailto:sdlcommute@gmail.com">
              <img src={Social2} class="Foundation" alt="found" />{' '}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
