import React from 'react';
import { Link } from 'react-router-dom';

import pic from '../images/profile.JPG';
import logo from '../images/logo-3.png';

const About = () => {
  return (
    <div class="collect">
      <nav>
        <Link to="/">
          <img src={logo} class="ulti" alt="logo" />
        </Link>
      </nav>
      <img src={pic} class="thee" style={{ objectFit: 'cover' }} alt="pic" />
      <div class="sdl-text">
        <h1 style={{ fontFamily: 'CIRCULAR' }}>ABOUT</h1>
        <br />
        <p style={{ fontFamily: 'CIRCULAR' }}>
          Temidayo SDL Arise, known professionally as SDL, is a multifaceted
          visual artist born and raised in the city of Ilorin, Nigeria. He is
          known for his captivating artworks with thought-provoking concepts and
          a diverse range of themes. With his keen eye for detail and his
          passion for creative expression, SDL's work captures ideals and
          emotions infused with the mystery and complexities of human existence.
          His portfolio reflects his evolution as an artist and a world he has
          spent years constructing.
          <br />
          <br />
          <p style={{ fontFamily: 'CIRCULAR' }}>EXHIBITIONS</p>
          <p style={{ fontFamily: 'CIRCULAR' }}>
            <b>The Artist Experience by The Process Africa</b> Lagos, Nigeria.
            December 2023
          </p>
          <p style={{ fontFamily: 'CIRCULAR' }}>
            <b>The Artist Commune</b> Lagos, Nigeria. May 2023
          </p>
          <p style={{ fontFamily: 'CIRCULAR' }}>
            <b>Galeria Lisboa by AW, Convento Do Beato</b> Lisbon, Portugal.
            October 2022
          </p>
          <p style={{ fontFamily: 'CIRCULAR' }}>
            <b>Lagos Gallery by AW</b> Lagos, Nigeria. December 2021
          </p>
          <p style={{ fontFamily: 'CIRCULAR' }}>
            <b>The Miami ART Basel</b> Miami, USA. November 2021
          </p>
          <br />
          <p style={{ fontFamily: 'CIRCULAR' }}>PUBLICATIONS</p>
          <p style={{ fontFamily: 'CIRCULAR' }}>
            Aké Review Volume XI, Pg. 35, Pg. 111
          </p>
          <p style={{ fontFamily: 'CIRCULAR' }}>
            <a href="https://the49thstreet.com/49th-exclusive-sdl-just-wants-to-be-heard/">
              https://the49thstreet.com/49th-exclusive-sdl-just-wants-to-be-heard/
            </a>
          </p>
          <p style={{ fontFamily: 'CIRCULAR' }}>
            <a href="https://sevenmagazine.ca/SDL">
              https://sevenmagazine.ca/SDL
            </a>
          </p>
          <p style={{ fontFamily: 'CIRCULAR' }}>
            <a href="  https://www.prazzleinc.com/prazzle-selects/mitochondria">
              {' '}
              https://www.prazzleinc.com/prazzle-selects/mitochondria
            </a>
          </p>
          <p style={{ fontFamily: 'CIRCULAR' }}>
            <a href="https://theprocessmag.substack.com/p/the-artist-inspired-by-beauty">
              https://theprocessmag.substack.com/p/the-artist-inspired-by-beauty
            </a>
          </p>
          <h5 style={{ fontFamily: 'CIRCULAR' }}>
            CONTACT ; <br />
            <br />
            <a
              style={{ fontFamily: 'CIRCULAR' }}
              href="mailto:sdlcommute@gmail.com"
            >
              EMAIL : sdlcommute@gmail.com
            </a>
          </h5>
        </p>
      </div>
    </div>
  );
};

export default About;
